import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
      primary: {
          main: '#1976d2',
      },
      secondary: {
          main: '#1976d2',//'#dc004e',
      },
      success: {
          main: '#4caf50',
      },
      error: {
          main: '#f44336',
      },
      default: {
          main: '#1976d2',
      }
  },
});

// const EC2_URL = 'http://ec2-34-234-201-2.compute-1.amazonaws.com'
const EC2_URL = 'https://344h73m8ih.execute-api.us-east-1.amazonaws.com/scada'
const IAD_ACL = 'acl-0d8e5ff04eb41e57c'
const FRA_ACL = 'acl-688c7702'
const IAD = 'us-east-1'
const FRA = 'eu-central-1'

const App = () => {

  const [status, setStatus] = useState({
      IAD: 'default',
      FRA: 'default',
  });

  const fetchStatus = async (region, aclId) => {
      try {
          const response = await fetch(`${EC2_URL}/get_status`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ region, aclId }),
          });
          const data = await response.json();
          setStatus(prevStatus => ({ ...prevStatus, [region]: data.status === 'allowed' ? 'active' : 'standby' }));
      } catch (error) {
          console.error('Error fetching status:', error);
      }
  };

  useEffect(() => {
      fetchStatus(IAD, IAD_ACL);
      fetchStatus(FRA, FRA_ACL);
  }, []);

  const handleToggle = async (region, aclId) => {
    
    try {
          // us-east-1
          region = IAD
          aclId = IAD_ACL
          let response = await fetch(`${EC2_URL}/toggle_traffic`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ region, aclId }),
          });
          let data = await response.json();
          setStatus(prevStatus => ({ ...prevStatus, [region]: data.status === 'allow' ? 'active' : 'standby' }));

          region = FRA
          aclId = FRA_ACL
          // eu-central-1
          response = await fetch(`${EC2_URL}/toggle_traffic`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ region, aclId }),
          });
          data = await response.json();
          setStatus(prevStatus => ({ ...prevStatus, [region]: data.status === 'allow' ? 'active' : 'standby' }));
      } catch (error) {
          console.error('Error toggling traffic:', error);
      }
  };

  const handleAllow = async () => {
      try {
          const response = await fetch(`${EC2_URL}:5000/allow_traffic`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ region: IAD, aclId: IAD_ACL }),
          });
          const data = await response.json();
          console.log(data);
          setStatus({ ...status, IAD: 'allowed' });
      } catch (error) {
          console.error('Error allowing traffic:', error);
      }
  };

  const handleDeny = async () => {
      try {
          const response = await fetch(`${EC2_URL}:5000/deny_traffic`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ region: FRA, aclId: FRA_ACL }),
          });
          const data = await response.json();
          console.log(data);
          setStatus({ ...status, FRA: 'denied' });
      } catch (error) {
          console.error('Error denying traffic:', error);
      }
  };

  const handleReset = async () => {
    try {
        let response = await fetch(`${EC2_URL}:5000/allow_traffic`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ region: IAD, aclId: IAD_ACL }),
        });
        let data = await response.json();
        console.log(data);
        response = await fetch(`${EC2_URL}:5000/allow_traffic`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ region: FRA, aclId: FRA_ACL }),
        });
        data = await response.json();
        console.log(data);
        setStatus({
            IAD: 'default',
            FRA: 'default',
        });
    } catch (error) {
        console.error('Error resetting traffic:', error);
    }
};

  return (
      <ThemeProvider theme={theme}>
          <Typography variant="h4" component="div" gutterBottom>
              SCADA Control Interface
          </Typography>
          <Grid container spacing={3} style={{ marginTop: 20 }}>
              <Grid item xs={12} md={6}>
                  <Card>
                      <CardContent>
                          <Typography variant="h5" component="div" gutterBottom>
                              North Virginia (us-east-1)
                          </Typography>
                          <Button
                              variant="contained"
                              color={status[IAD] === 'active' ? 'success' : 'error'}
                              onClick={() => handleToggle(IAD, IAD_ACL)}
                              fullWidth
                          >
                              {status[IAD] === 'active' ? 'Primary SCADA' : 'Secondary SCADA'}
                          </Button>
                      </CardContent>
                  </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                  <Card>
                      <CardContent>
                          <Typography variant="h5" component="div" gutterBottom>
                              Frankfurt (eu-central-1)
                          </Typography>
                          <Button
                              variant="contained"
                              color={status[FRA] === 'active' ? 'success' : 'error'}
                              onClick={() => handleToggle(FRA, FRA_ACL)}
                              fullWidth
                          >
                              {status[FRA] === 'active' ? 'Primary SCADA' : 'Secondary SCADA'}
                          </Button>
                      </CardContent>
                  </Card>
              </Grid>
              {/* <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleReset}
                                fullWidth
                            >
                                Reset Traffic
                            </Button>
                        </CardContent>
                    </Card>
                </Grid> */}
          </Grid>
      </ThemeProvider>
  );
};

export default App;
